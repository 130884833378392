import React from 'react'
import { Link, graphql } from 'gatsby'
import loadable from '@loadable/component'
import Layout from '../../components/Layout'
import Helmet from 'react-helmet'
import Img from "gatsby-image"
import { InlineWidget } from "react-calendly";

const FormMsg = loadable(() => import('../../components/FormMsg'));
const Typewriter = loadable(() => import('typewriter-effect'));
// const MapContainer = loadable(() => import('../../components/Map'));

export default class Index extends React.Component {

  componentDidMount(){

      var lazyBackgrounds = [].slice.call(
        document.querySelectorAll(".lazy-load")
      );

      if (
        "IntersectionObserver" in window &&
        "IntersectionObserverEntry" in window &&
        "intersectionRatio" in window.IntersectionObserverEntry.prototype
      ) {
        let lazyBackgroundObserver = new IntersectionObserver(function (
          entries,
          observer
        ) {
          entries.forEach(function (entry) {
            if (entry.isIntersecting) {
              if(entry.target.id === 'calendly'){
               //console.log('calendly')
              } else if (entry.target.id === 'mapp'){
               //console.log('map')
              }
              lazyBackgroundObserver.unobserve(entry.target);

            }
          });
        });
        lazyBackgrounds.forEach(function (lazyBackground) {
          lazyBackgroundObserver.observe(lazyBackground);
        });
      }
    ;
    document.getElementById('lang-switch').href = 'https://e-multicontent.com/contact-us/'
  }

  render() {
    const { data } = this.props
    return (
      <>
      <Helmet titleTemplate="Kontakt | e-multicontent.pl">
        <title>Kontakt | e-multicontent.pl</title>
        <meta name="description" content={'Przygotujemy content graficzny, zdjęcia oraz video do sklepu internetowego już od 50 zł netto. Sprawdź! ✔️'}/>
        <meta name='image' content={'https://e-multicontent.pl/img/meta/kontakt-meta.jpg'} />
        <meta name='keywords' content={'copywriting, copywriterzy, marketing, agencja copywriterska, Wrocław, zespół copywriterów, praca, biznes, treści, content, tłumaczenia, SEO, blog, teksty, artykuły, grafika, video, strony internetowe, sklepy internetowe, marketplace, e-commerce'} />
        <meta property="og:description" content={'Przygotujemy content graficzny, zdjęcia oraz video do sklepu internetowego już od 50 zł netto. Sprawdź! ✔️'}/>
        <meta property='og:title' content={'Kontakt z e-multicontent.pl | biuro@e-multicontent.pl |  Wrocław'} />
        <meta property='og:image' content={'https://e-multicontent.pl/img/meta/kontakt-meta.jpg'} />
        <meta property="og:url" content={'https://e-multicontent.pl/kontakt/'}/>

        <link rel="alternate" href="https://e-multicontent.com/contact-us/" hrefLang="en-gb" />
        <link rel="alternate" href="https://e-multicontent.com/contact-us/" hrefLang="en-us" />
        <link rel="canonical" href="https://e-multicontent.pl/kontakt/"  />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="e-multicontent.pl" />
        <meta property="twitter:url" content="https://e-multicontent.pl/kontakt/" />
        <meta name="twitter:title" content="Kontakt z e-multicontent.pl | biuro@e-multicontent.pl |  Wrocław" />
        <meta name="twitter:description" content="Przygotujemy content graficzny, zdjęcia oraz video do sklepu internetowego już od 50 zł netto. Sprawdź! ✔️" />
        <meta name="twitter:image" content="https://e-multicontent.pl/img/apple-touch-icon.png" />

        <script type='application/ld+json'>
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://e-multicontent.pl/"
          },{
            "@type": "ListItem",
            "position": 2,
            "name": "Kontakt",
            "item": "https://e-multicontent.pl/kontakt/"
          }]
        })}
        </script>

      </Helmet>
      <Layout>
      <nav className="breadcrumbs-nav section">
          <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600'}}><b>Kontakt</b></span>
      </nav>

      <div
        id="kontakt"
        className="full-width-image-container"
        style={{
          backgroundPosition: `75% center`,
          backgroundSize:'cover',
          transform: 'scale(1)',
          height:'509px',
          backgroundRepeat: 'no-repeat',
          paddingTop: '0px',
        }}
      >
        </div>
        <h1
          className="has-text-weight-bold is-size-1"
          style={{
            lineHeight: '1',
            padding: '0.35em',
            borderRadius:'10px',
            marginTop: '0%',
            display: 'block',
            position: 'absolute',
            left: '7%',
            top: '200px',
            color:'#36b3d2',
            textAlign: 'left',
            backgroundColor:'rgba(255,255,255,0.7)',
          }}
        >
        Kontakt
        <br></br>
        </h1>
        <br></br>
        <h2
        className="mright"
        style={{
          color: 'rgb(23,19,43)',
          lineHeight: '1',
          padding: '0.35em',
          borderRadius:'10px',
          marginTop: '0%',
          display: 'block',
          position: 'absolute',
          left: '8%',
          top: '350px',
          maxWidth:'300px',
          textAlign: 'left',
          backgroundColor:'rgba(255,255,255,0.8)',}} >W naszej branży kontakt z Klientem i bezpośrednia rozmowa mają kluczowe znaczenie.</h2>



            <div style={{marginTop:'-50px'}}>



            <div style={{width:'100%'}}>
              <div style={{padding:'5%',paddingBottom:'2.5%',paddingTop:'2%',marginLeft:'2.5%'}}>
                <h3> Dlatego też zawsze jesteśmy do Waszej dyspozycji pod telefonem i mailem. <br />Możemy również spotkać się bezpośrednio w naszej agencji lub w siedzibie Waszej firmy, a także zorganizować spotkanie online. Prosimy o wcześniejsze ustalenie terminu spotkania.<br />
                 <br />Z przyjemnością odpowiemy na wszystkie, interesujące Was pytania i przygotujemy indywidualnie skrojoną ofertę.</h3>
                 <br />
                <h3 className="is-size-2" style={{fontWeight:'800'}}>
                <Typewriter
                    onInit={(typewriter) => {
                      typewriter.typeString("<span style='color:#36b3d2'>Zapraszamy</span> do kontaktu!")
                        .pauseFor(2500)
                        .deleteAll()
                        .start();
                    }}
                    options={{
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </h3>
                <br />
                <p> Poniżej znajdziecie Państwo wszelkie potrzebne dane kontaktowe:</p>
                <sub>Biuro czynne w godzinach od <b>8 do 16</b> od <b>poniedziałku do piątku.</b></sub>
                <br />
                <br />
              </div>

              <p className="ux" align="center" id="porozmawiajmy"><a className="btnoutline" href="#porozmawiajmy">⇩</a></p>
              <br />
              <br />
              <div style={{backgroundColor:'rgb(245, 245, 245)',padding:'5%'}}>

              <div className="columns" style={{margin:'auto'}}>

              <div className="column" style={{textAlign:'center'}}>
              <br />  <br />
              <div className="box lazy-load" id="calendly" style={{minWidth:'100%',height:'780px'}}>
              <InlineWidget url="https://calendly.com/a-filip" />
              </div>
              <br /><br />
              <Img fluid={data.file1.childImageSharp.fluid}
              imgStyle={{objectFit:'contain'}}
              alt="Kontakt z e-multicontent - copywriting, tłumaczenia, seo, strony internetowe, e-commerce, marketplace"
              style={{
                padding: "0",
                borderRadius: "10px",width:'100%',minHeight:'244px',
                maxWidth:'550px', border:'3px solid #f5f5f5',margin:'auto',marginBottom:'25px',
                backgroundColor: 'white',objectFit:'contain',objectPosition:'top center'}} />
              </div>

              <div className="column" style={{position:'relative',textAlign:'center'}}>

              <h3 style={{fontSize:'1em',textAlign:'center'}}>Wypełnij formularz poniżej, możesz również załączyć plik.</h3>
              <br />
              <FormMsg/>


              <br /><br />
              <h3 style={{fontSize:'1em'}}>Chcesz otrzymać ofertę dla siebie?<br />Wypełnij brief.</h3>
              <br />
              <a className="btn" style={{fontSize:'1.1em'}}
              href="https://docs.google.com/forms/d/e/1FAIpQLSdkSrYDjdS0qddoWvk7555qSST6N5Z4qpBcrVWoy7aZQ2_SqA/viewform"
              target="_blank" rel="noopener noreferrer">Brief e-multicontent &nbsp; &nbsp;
              <img width="15px" height="22px"   style={{verticalAlign:'middle', marginRight:'5px'}}   alt="brief Agencja Copywriterska Wrocław - tłumaczenia i teksty, seo dla stron internetowych, e-commerce i na marketplace" src='/img/angle-right.svg'/></a>
              <br /><br />

              <h3 style={{fontSize:'1em'}}>Chcesz wysłać maila?</h3>
              <br />
              <a className="btn" style={{fontSize:'1.1em'}}
              href="mailto:biuro@e-multicontent.pl"
              target="_blank" rel="noopener noreferrer">Wyślij e-mail &nbsp; &nbsp;
              <img width="15px" height="22px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="brief Agencja Copywriterska Wrocław - tłumaczenia i teksty, seo dla stron internetowych, e-commerce i na marketplace" src='/img/angle-right.svg'/></a>
              <br /><br />

              <h3 style={{fontSize:'1em'}}>Czy może wolisz porozmawiać?</h3>
              <br />
              <a className="btn" style={{fontSize:'1.1em'}}
              href="tel:+48713074517"
              target="_blank" rel="noopener noreferrer">Zadzwoń do nas &nbsp; &nbsp;
              <img width="15px" height="22px"   style={{verticalAlign:'middle', marginRight:'5px'}}   alt="brief Agencja Copywriterska Wrocław - tłumaczenia i teksty, seo dla stron internetowych, e-commerce i na marketplace" src='/img/angle-right.svg'/></a>
              <br /><br />

              <h3 style={{fontSize:'1em'}}>Złóż nam wizytę przy najbliższej okazji:</h3>
              <br />
              <a className="btn" style={{fontSize:'1.1em'}} href="https://www.google.com/maps/place/e-multicontent+-+wielojęzyczna+agencja+copywriterska/@51.1024556,17.0255398,17z/data=!3m1!4b1!4m5!3m4!1s0x470fc3f3c0f0f433:0xc6a93a9350239ead!8m2!3d51.1024565!4d17.0277043"
              target="_blank" rel="noopener noreferrer">Katarzyńska 1 F, 55-010 Radwanice&nbsp; &nbsp;
              <img width="15px" height="22px"   style={{verticalAlign:'middle', marginRight:'5px'}}   alt="brief - Agencja Copywriterska Wrocław - tłumaczenia i teksty, seo dla stron internetowych, e-commerce i na marketplace" src='/img/angle-right.svg'/></a>
              <br /><br />

              </div>

              </div>



              <br /><br />
              <div style={{textAlign:'center'}}>
              <b>Właścicielem marki <Link className="dedlink" to="/">e<span style={{color:'#4a4a4a'}}>-multicontent</span></Link> jest:</b><br />
              <a className="partners" target="_blank" rel="noopener noreferrer" href="https://iv-group.pl">
              <Img
              fluid={data.file2.childImageSharp.fluid}
              alt="Innovision Group"
              imgStyle={{objectFit:'contain'}}
              style={{
                padding: '0',
                width:'100%',minHeight:'50px',
                maxWidth:'200px',margin:'25px auto',
                objectFit:'contain',objectPosition:'center center'}}
                 />
               </a>
               <br />INNOVISION GROUP A. FILIP SPÓŁKA KOMANDYTOWA<br />
               <a className="dedlink" href="mailto:info@iv-group.pl" target="_blank" rel="noopener noreferrer">info@iv-group.pl</a>
               <br /><br />
               ul. Pirenejska 2/8<br />
               50-574 Wrocław<br /><br />
               NIP: 8971898232<br />
               REGON: 520421727<br />
               KRS: 0000932139<br />
               </div>


              </div>
              </div>



          </div>

          <div style={{width:'100%',paddingLeft:'10%',paddingRight:'10%',backgroundColor:'rgb(245, 245, 245)',paddingBottom:'50px',textAlign:'center'}}>
          <Link className="btn" to="/"> Home </Link>&nbsp;&nbsp;&nbsp;
          <Link to="/o-nas/" className="btn"> O nas </Link> &nbsp;&nbsp;&nbsp;
          <Link to="/oferta/" className="btn">Oferta</Link>&nbsp;&nbsp;&nbsp;
          <Link className="btn" to="/blog/"> Blog </Link>&nbsp;&nbsp;&nbsp;
          <Link to="/portfolio/" className="btn"> Portfolio </Link> &nbsp;&nbsp;&nbsp;
          <Link to="/kariera/" className="btn"> Kariera </Link> &nbsp;&nbsp;&nbsp;
          <a className="btn" href="#newsletter"> Newsletter </a>&nbsp;&nbsp;&nbsp;
          <Link className="btn" to="/ebook/"> E-book </Link>&nbsp;&nbsp;&nbsp;
          </div>

          <div className="lazy-load" id="mapp">
        {/*<MapContainer />*/}
        <iframe title="google map"
src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10026.38890761939!2d17.0209058!3d51.0789848!3
m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fc3f3c0f0f433%3A0xc6a93a9350239ead!2semulticontent!5e0!3m2!1spl!2spl!4v1694689330347!5m2!1spl!2spl" width="100%" height="550" style={{border:'0',marginBottom:'-10px'}}
allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>

        {/*  <div
            className="full-width-image-container eight lazy-background"
            style={{
              backgroundColor: '#36b3d2',
              backgroundPosition: `bottom left`,
              backgroundSize:'cover',
              backgroundRepeat:'no-repeat',
              height:'470px',
              marginBottom:'-50px',
              marginTop:'0px'
            }}
          ></div>*/}



      </Layout>
      </>
    )
  }

}



export const query = graphql`
  query Files{
    file1: file(relativePath: {regex: "/optimized/kontakt.jpg/"}) {
      childImageSharp {
        fluid(fit: CONTAIN) {
        srcWebp
        src
        srcSet
        srcSetWebp
      }
      }
    }
    file2: file(relativePath: {regex: "/optimized/logo_innovision.png/"}) {
      childImageSharp {
        fluid(fit: CONTAIN) {
        src
        srcSet
        originalImg
        srcSetWebp
      }
      }
    }
  }
`
